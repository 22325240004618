import { format } from "date-fns";

import type { Maybe } from "./types";

export const slotMapping = {
  "08-12": 8,
  "12-16": 12,
  "16-20": 16,
};

type Slot = keyof typeof slotMapping;

export const getSlotDateTime = (slotTime: Maybe<string>) => {
  const [date, slot] = slotTime?.split("||") ?? [];
  const startTime = new Date(date);
  startTime.setHours(slotMapping[slot as Slot]);
  return startTime;
};

export const formatSlotTime = (slotTime: Maybe<string>) => {
  const startTime = getSlotDateTime(slotTime);
  if (startTime instanceof Date && startTime.toJSON()) {
    return format(startTime, "dd/MM/yyyy HH:mm");
  } else {
    return "Invalid time";
  }
};
