import { capitalCase } from "change-case";
import type { JobEvent } from "src/services/models/Job";
import { JobJobTypeEnum, JobStatus } from "src/services/models/Job";

export const jobStatusToLabel: Record<JobStatus, string> = {
  [JobStatus.UNALLOCATED]: "Unallocated",
  [JobStatus.BOOKED]: "Booked",
  [JobStatus.UNBOOKED]: "Unbooked",
  [JobStatus.UNASSIGNED]: "Unassigned",
  [JobStatus.COMPLETED]: "Completed",
  [JobStatus.CUSTOMER_PAID]: "Customer Paid",
  [JobStatus.NO_ACCESS]: "No Access",
  [JobStatus.QUOTE_SENT_TO_OPS]: "Quote sent to Ops",
  [JobStatus.JOBSHEET_APPROVAL_REQUIRED]: "Awaiting Jobsheet Approval",
  [JobStatus.QUOTE_SENT_TO_RESIDENT]: "Quote sent to Resident",
  [JobStatus.QUOTE_APPROVED]: "Quote Approved",
  [JobStatus.QUOTE_DECLINED]: "Quote Declined",
  [JobStatus.FAILED_TO_AUTO_ALLOCATE]: "Failed to Auto Allocate",
  [JobStatus.PENDING_PAYMENT]: "Pending Payment",
};

export const jobStatusColor: Record<JobStatus, string> = {
  [JobStatus.BOOKED]: "#0B4ED5",
  [JobStatus.UNALLOCATED]: "#9f8b7a",
  [JobStatus.UNBOOKED]: "#DAA402",
  [JobStatus.UNASSIGNED]: "#FF7700",
  [JobStatus.COMPLETED]: "#0DBB7C",
  [JobStatus.CUSTOMER_PAID]: "#0DBB7C",
  [JobStatus.NO_ACCESS]: "#FF7700",
  [JobStatus.FAILED_TO_AUTO_ALLOCATE]: "#E92C2C",
  [JobStatus.QUOTE_SENT_TO_OPS]: "#FF7700",
  [JobStatus.JOBSHEET_APPROVAL_REQUIRED]: "#FF7700",
  [JobStatus.QUOTE_SENT_TO_RESIDENT]: "#FF7700",
  [JobStatus.QUOTE_APPROVED]: "#FF7700",
  [JobStatus.QUOTE_DECLINED]: "#FF7700",
  [JobStatus.PENDING_PAYMENT]: "#DAA402",
};

export const jobTypeColour: Record<JobJobTypeEnum, string> = {
  [JobJobTypeEnum.HeatingRecall]: "#6237B5",
  [JobJobTypeEnum.HeatingRepair]: "#6237B5",
  [JobJobTypeEnum.Powerflush]: "#6237B5",
  [JobJobTypeEnum.ElectricalRepair]: "#6237B5",

  [JobJobTypeEnum.BoilerService]: "#04A1F0",
  [JobJobTypeEnum.BoilerServiceRecall]: "#04A1F0",

  [JobJobTypeEnum.BoilerServiceCombo]: "#1e20b3",
  [JobJobTypeEnum.BoilerServiceLandlord]: "#1e20b3",
  [JobJobTypeEnum.BoilerInstallation]: "#1e20b3",

  [JobJobTypeEnum.PlumbingRepair]: "#ea6be8",
  [JobJobTypeEnum.PlumbingRecall]: "#ea6be8",
  [JobJobTypeEnum.DrainageRepair]: "#ea6be8",
  [JobJobTypeEnum.TapsAndToiletsRepair]: "#ea6be8",

  [JobJobTypeEnum.InitialInspection]: "#4EAB00",
  [JobJobTypeEnum.InitialInspectionLandlord]: "#4EAB00",

  [JobJobTypeEnum.FireInstallation]: "#E28D76",
  [JobJobTypeEnum.FireService]: "#E28D76",

  [JobJobTypeEnum.Personal]: "#44aa8a",

  [JobJobTypeEnum.HomeEnergyAssessment]: "#44aa8a",
  [JobJobTypeEnum.SmartThermostatInstall]: "#44aa8a",
  [JobJobTypeEnum.SmartThermostatInstallRecall]: "#44aa8a",
};

const JOB_TYPES_TO_AUTO_ALLOCATE = [
  "heating_repair",
  "heating_recall",
  "plumbing_repair",
  "plumbing_recall",
  "smart_thermostat_install_recall",
];

const jobEventLabel: Record<JobEvent["eventType"], string> = {
  booked: "Job Booked",
  cancelled: "Job Cancelled",
  closed: "Job Closed",
  declined: "Job Declined",
  no_access: "Job No Access",
  rescheduled_by_contact: "Job Rescheduled",
  rescheduled_by_partner: "Job Rescheduled",
  booking_reset_by_partner: "Booking Reset",
  booking_reset_by_tradesperson: "Booking Reset",
  marked_as_incomplete: "Job Marked as Incomplete",
  unallocated_by_partner: "Job Unallocated",
  auto_allocation_restarted: "Auto Allocation Restarted",
  tag_added: "Job Tag Added",
  tag_removed: "Job Tag Removed",
  started_travel_to_job: "Travel to job started",
  started: "Opened Jobsheet",
  completed: "Job Completed",
  allocation_expired: "Allocation Expired",
  costs_pre_approved: "Cost Pre-Approved",
  jobsheet_submitted: "Jobsheet Submitted",
  note_added: "Note Added",
  people_added: "Initials Added",
  people_removed: "Initials Removed",
  quote_approved: "Quote Approved",
  quote_declined: "Quote Declined",
  quote_sent_to_ops: "Quote Sent to Ops",
  quote_sent_to_resident: "Quote Sent to Resident",
  tradesperson_allocated: "Tradesperson Allocated",
  comm_sent: "Email Sent",
  payment_comm_sent: "Invoice Sent",
  payment_reminder_sent: "Invoice Reminder Sent",
  jobsheet_approval_required: "Job flagged as requiring jobsheet approval",
  cancelled_by_customer: "Cancelled by customer",
};

export const jobEventToLabel = (eventType: JobEvent["eventType"]) => {
  return jobEventLabel[eventType] ?? capitalCase(eventType);
};

export const humanizeString = (str: string) => {
  return capitalCase(str);
};

export const isCallout = (jobType?: string): boolean => {
  return !!jobType && JOB_TYPES_TO_AUTO_ALLOCATE.includes(jobType);
};

export const isRepair = (jobType?: string): boolean => {
  return !!jobType && jobType.endsWith("repair");
};

const isRecall = (jobType?: string): boolean => {
  return !!jobType && jobType.endsWith("recall");
};

export const doesJobTypeSupportQuotes = (jobType?: string): boolean => {
  return isRepair(jobType) || isRecall(jobType);
};

export const getShortReferenceId = (jobReferenceId: string) => {
  return jobReferenceId.substring(0, 8).toUpperCase();
};

// taken from kantan-app, might make sense to move to kantan-js-utils at some point
export const capitalizeAllWords = (str: string) => {
  return str
    .split(" ")
    .map((word) => capitalCase(word))
    .join(" ");
};

export const reformatTransactionRef = (uuid: string) => {
  if (uuid.includes("-")) {
    return uuid.split("-")[0];
  }

  return `${uuid.slice(0, 8)}`;
};
