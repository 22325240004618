import Close from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { grey } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useMemo } from "react";

import { TagEntityType } from "src/services/models/Tag";
import { useDeleteEntityTag } from "src/services/mutations";
import { useEntityTags } from "src/services/queries";
import { shouldForwardProp } from "src/utils/shouldForwardProp";
import { useTagsById } from "src/hooks/useTags";

import { ModalContainer, ModalHeader } from "../AddTagModal";
import { ReviewEntityTag } from "../EntityTag";
// eslint-disable-next-line
import { styled } from "@mui/system";

type ReviewTradespersonTagsModalProps = {
  tradespersonId: string;
  onClose: (reason: "close" | "mutate") => void;
};

export const ReviewTradespersonTagsModal = ({
  tradespersonId,
  onClose,
}: ReviewTradespersonTagsModalProps) => {
  const entityTagsQuery = useEntityTags({
    variables: {
      tradespersonId,
    },
    staleTime: 0,
  });

  const deleteEntityTagMutation = useDeleteEntityTag();
  const { refetchTags } = useTagsById();

  const deleteEntityTag = async (entityTagId: string) => {
    try {
      await deleteEntityTagMutation.mutateAsync({ entityTagId });
      refetchTags();
      onClose("mutate");
    } catch (e) {}
  };

  const tpEntityTags = useMemo(() => {
    return (entityTagsQuery.data || []).filter(
      (entityTag) => entityTag.source === TagEntityType.Tradesperson,
    );
  }, [entityTagsQuery.data]);

  const jobsEntityTags = useMemo(() => {
    return (entityTagsQuery.data || []).filter(
      (entityTag) => entityTag.source === TagEntityType.Job,
    );
  }, [entityTagsQuery.data]);

  if (entityTagsQuery.isLoading) {
    return (
      <Modal data-testid="add-tags-modal" open={true} onClose={close}>
        <ModalContainer>
          <ModalHeader>
            <Stack
              gap="5px"
              justifyContent="space-between"
              alignItems="center"
              direction="row"
              flexWrap="nowrap"
            >
              <Typography noWrap variant="h3">
                Review Tags
              </Typography>
              <IconButton aria-label="close" onClick={() => onClose("close")}>
                <Close />
              </IconButton>
            </Stack>
          </ModalHeader>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        </ModalContainer>
      </Modal>
    );
  }

  return (
    <Modal data-testid="add-tags-modal" open={true} onClose={() => onClose("close")}>
      <ModalContainer>
        <ModalHeader>
          <Stack
            gap="5px"
            justifyContent="space-between"
            alignItems="center"
            direction="row"
            flexWrap="nowrap"
          >
            <Typography noWrap variant="h3">
              Review Tags
            </Typography>
            <IconButton aria-label="close" onClick={() => onClose("close")}>
              <Close />
            </IconButton>
          </Stack>
        </ModalHeader>
        <Typography noWrap variant="body1" color={grey["600"]} sx={{ marginBottom: "-10px" }}>
          From Admins
        </Typography>
        <StackStyled data-testid="tags-list" spacing={1}>
          {Object.values(tpEntityTags).map((entityTag) => {
            return (
              <ReviewEntityTag
                key={`entity-tag-id-${entityTag.id}`}
                entityTag={entityTag}
                deleteEntityTag={deleteEntityTag}
                showFromJobLabel
              />
            );
          })}
        </StackStyled>
        <Typography noWrap variant="body1" color={grey["600"]} sx={{ marginBottom: "-10px" }}>
          From Jobs
        </Typography>
        <StackStyled data-testid="tags-list" spacing={1}>
          {Object.values(jobsEntityTags).map((entityTag) => {
            return (
              <ReviewEntityTag
                key={`entity-tag-id-${entityTag.id}`}
                entityTag={entityTag}
                deleteEntityTag={deleteEntityTag}
                showFromJobLabel
              />
            );
          })}
        </StackStyled>
      </ModalContainer>
    </Modal>
  );
};

// eslint-disable-next-line
export const StackStyled: any = styled(Stack, { shouldForwardProp: shouldForwardProp })`
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: lightgrey;
  }
`;
