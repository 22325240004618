import { useRouter } from "next/router";
import { useEffect } from "react";

import { useAuthStore } from "src/context/authStore";

import { useIsAdmin } from "./useIsAdmin";

const basicFeatures = ["jobs", "appointments", "tradespeople"] as const;
const adminFeatures = ["bills", "invoices", "admin"] as const;

const features = [...basicFeatures, ...adminFeatures] as const;

type Feature = (typeof features)[number];

function useFeatureEnabled(feature: Feature): boolean {
  const isAdmin = useIsAdmin();
  const permissions = useAuthStore((state) => state.permissions);

  if (isAdmin) return true;

  return permissions.includes(feature.toUpperCase());
}

export function useAuthorisationGuard(feature: Feature) {
  const featureEnabled = useFeatureEnabled(feature);
  const router = useRouter();
  useEffect(() => {
    if (!featureEnabled) {
      router.replace("/");
    }
  }, [featureEnabled, router]);
}
