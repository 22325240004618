import Close from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import { grey } from "@mui/material/colors";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import styled from "@mui/system/styled";
import React, { useState } from "react";

import { useTagsById } from "src/hooks/useTags";
import type { EntityTag } from "src/services/models/EntityTag";
import { TagEntityType } from "src/services/models/Tag";
import { useCreateEntityTags } from "src/services/mutations";
import { isNotNullish } from "src/utils/isNotNullish";

interface AddTagsModalProps {
  jobId?: string;
  tradespersonId?: string;
  invoiceId?: string;
  source: TagEntityType;
  isOpen: boolean;
  onClose: (reason: "close" | "mutate") => void;
}

export function AddTagsModal({
  jobId,
  tradespersonId,
  invoiceId,
  source,
  onClose,
  isOpen,
}: AddTagsModalProps) {
  const { tagsById, tagsByEntityType, refetchTags } = useTagsById();
  const createEntityTags = useCreateEntityTags();
  const [selectedTagIds, setSelectedTagIds] = useState<string[]>([]);
  const [snackBarMessage, setSnackBarMessage] = useState<string | null>(null);
  const [addToTPProfile, setAddToTPProfile] = useState<boolean>(false);

  const onTagSelected = (selectedTagId: string) => {
    if (selectedTagIds.includes(selectedTagId)) {
      const newTagIds = selectedTagIds.filter((tagId) => tagId !== selectedTagId);
      setSelectedTagIds(newTagIds);
    } else {
      selectedTagIds.push(selectedTagId);
      setSelectedTagIds([...selectedTagIds]);
    }
  };

  const onAddTagsClicked = async () => {
    const entityTagsToCreate: Partial<EntityTag>[] = selectedTagIds
      .map((tagId) => {
        const entityTag: Partial<EntityTag> = { tagId, tradespersonId, jobId, invoiceId, source };

        const tag = tagsById[tagId];

        if (!tag) {
          return undefined;
        }

        if (addToTPProfile && !tag.entityTypes.includes(TagEntityType.Tradesperson)) {
          entityTag.tradespersonId = undefined;
        }

        if (!addToTPProfile && source === TagEntityType.Job) {
          entityTag.tradespersonId = undefined;
        }

        return entityTag;
      })
      .filter(isNotNullish);

    try {
      await createEntityTags.mutateAsync(entityTagsToCreate);
      refetchTags();

      setSnackBarMessage("Tags Added Successfully");
      setSelectedTagIds([]);
      setAddToTPProfile(false);
      onClose("mutate");
    } catch (e) {
      alert("Error while adding tag");
    }
  };

  const close = () => {
    setSelectedTagIds([]);
    setAddToTPProfile(false);
    onClose("close");
  };

  return (
    <>
      <Modal data-testid="add-tags-modal" open={isOpen} onClose={close}>
        <ModalContainer>
          <ModalHeader>
            <Stack gap="5px" justifyContent="space-between" direction="row" flexWrap="nowrap">
              <Box>
                <Typography noWrap variant="h3">
                  Add Tags
                </Typography>
                <Typography noWrap variant="subtitle2">
                  Tap to select tag
                </Typography>
              </Box>

              <IconButton aria-label="close" onClick={close}>
                <Close />
              </IconButton>
            </Stack>
          </ModalHeader>

          <Stack data-testid="tags-list" direction="row" flexWrap={"wrap"} spacing={1}>
            {Object.values(tagsByEntityType[source]).map((tag, i) => {
              if (!tag) {
                return null;
              }
              return (
                <div key={`tag-id-${tag.id}`}>
                  <Chip
                    data-testid={`tag-item-${i}`}
                    label={<Typography variant="button"># {tag.label}</Typography>}
                    sx={{
                      marginBottom: "10px",
                      backgroundColor: selectedTagIds.includes(tag.id) ? "#E8EDF9" : grey["100"],
                    }}
                    onClick={() => onTagSelected(tag.id)}
                  />
                </div>
              );
            })}
          </Stack>

          <Box sx={{ marginTop: "auto", display: "flex" }}>
            {source === TagEntityType.Job && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      data-testid="add-to-tp-profile-checkbox"
                      checked={addToTPProfile}
                      onChange={(e) => {
                        setAddToTPProfile(e.target.checked);
                      }}
                    />
                  }
                  label="Add to TP Profile"
                />
              </FormGroup>
            )}

            <Button
              data-testid="add-tags-btn"
              disabled={!Boolean(selectedTagIds.length)}
              variant="contained"
              color="primary"
              onClick={onAddTagsClicked}
              sx={{ marginLeft: "auto" }}
            >
              Add Tags
            </Button>
          </Box>
        </ModalContainer>
      </Modal>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={Boolean(snackBarMessage)}
        onClose={() => {
          setSnackBarMessage(null);
        }}
        message={snackBarMessage}
        autoHideDuration={3000}
        ContentProps={{ sx: { backgroundColor: "success.main" } }}
      />
    </>
  );
}

export const ModalContainer = styled("div")`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 40%;
  max-width: 48em;
  min-height: 200px;

  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  flex-flow: column;
  align-items: stretch;

  padding-top: ${({ theme }) => theme.spacing(3)};
  padding-bottom: ${({ theme }) => theme.spacing(3)};
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  background-color: white;
  border-radius: 20px;
`;

export const ModalHeader = styled("div")`
  width: 100%;
`;
