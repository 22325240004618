import { useMemo } from "react";

import type { Tag } from "src/services/models/Tag";
import { TagEntityType } from "src/services/models/Tag";
import { useTags } from "src/services/queries";

export const MINUTE_IN_MILLIS = 60 * 1000;

export const useTagsById = () => {
  const tagsQuery = useTags({
    variables: {},
    keepPreviousData: true,
    staleTime: 15 * MINUTE_IN_MILLIS,
  });

  const tagsById = useMemo(() => {
    const map: Record<string, Tag | undefined> = {};

    tagsQuery.data?.forEach((tag) => {
      map[tag.id] = tag;
    });

    return map;
  }, [tagsQuery.data]);

  const tagsByEntityType = useMemo(() => {
    const map: Record<TagEntityType, Tag[]> = {
      [TagEntityType.Invoice]: [],
      [TagEntityType.Job]: [],
      [TagEntityType.Tradesperson]: [],
      [TagEntityType.Company]: [],
    };

    tagsQuery.data?.forEach((tag) => {
      if (tag.entityTypes.includes(TagEntityType.Invoice)) {
        map[TagEntityType.Invoice].push(tag);
      }

      if (tag.entityTypes.includes(TagEntityType.Job)) {
        map[TagEntityType.Job].push(tag);
      }

      if (tag.entityTypes.includes(TagEntityType.Tradesperson)) {
        map[TagEntityType.Tradesperson].push(tag);
      }

      if (tag.entityTypes.includes(TagEntityType.Company)) {
        map[TagEntityType.Company].push(tag);
      }
    });

    return map;
  }, [tagsQuery.data]);

  return { tagsById, tagsByEntityType, refetchTags: tagsQuery.refetch };
};
