import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Link from "next/link";
import React from "react";

import { useTradespersonByPartnerTradesperson } from "src/services/queries";

/**
 * Renders a link to a tradesperson given a partner id and a tp primary key.
 * NB: tp id is not a TTP reference id or a primary key, but the actual
 * underlying tp id that isn't normally exposed by the partner ids
 */
export function TradespersonByPartnerTradesperson(props: {
  partnerId: string;
  tradespersonId: string;
}) {
  const tp = useTradespersonByPartnerTradesperson({
    variables: { partnerId: props.partnerId, tradespersonId: props.tradespersonId },
  });

  if (tp.isLoading) {
    return <CircularProgress />;
  }

  if (!tp.data) return null;

  return (
    <Link
      href={{ pathname: "/tradespeople/[id]", query: { id: tp.data.referenceId } }}
      passHref
      legacyBehavior
    >
      <Button component="a" sx={{ padding: 0 }}>
        <Typography noWrap={true} variant="subtitle1" component="span">
          Tradeperson: {tp.data.tradesperson.fullName}
        </Typography>
      </Button>
    </Link>
  );
}
