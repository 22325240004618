export enum TagEntityType {
  Job = "job",
  Tradesperson = "tradesperson",
  Invoice = "invoice",
  Company = "company",
}

export interface Tag {
  id: string;
  createdAt: string;
  updatedAt: string;
  label: string;
  entityTypes: TagEntityType[];
  actorUserId?: string;
  count: number;
}
